import {
  BoxContentExplorer,
  BoxItem,
  useResponseInterceptor,
} from '@socialchorus/box-components';
import axios, { AxiosResponse } from 'axios';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  MemoryRouter,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import { Spinner } from '../../../components/ui/spinner';
import { filterBoxResponseByPermission } from '../../../services/box-integration';
import { uiOperations } from '../../../models/ui';
import { useBoxToken } from '../useBoxToken';
import styles from './styles.module.scss';

type RouteParams = {
  folderId: string;
};

export function ContentExplorer() {
  const { folderId } = useParams<RouteParams>();
  const history = useHistory();
  const { token, refetch } = useBoxToken({
    resourceType: 'folder',
    resourceId: folderId,
  });
  const location = useLocation();
  const dispatch = useDispatch();

  const permissionFilter = useCallback(
    async (
      response: AxiosResponse<BoxItem>
    ): Promise<AxiosResponse<BoxItem>> => {
      if (response.status === 404) {
        history.replace('/box/not-found');
      }

      if (response.status % 200 < 100) {
        return (await filterBoxResponseByPermission(response)).data.data;
      }
      return response;
    },
    [history]
  );

  const refetchToken = useCallback(
    async (response: AxiosResponse) => {
      const tokenData = await refetch();

      if (!tokenData) {
        return response;
      }

      return axios.request({
        ...response.config,
        headers: {
          ...response.config.headers,
          Authorization: `Bearer ${tokenData.token}`,
        },
      });
    },
    [refetch]
  );

  const responseInterceptor = useResponseInterceptor({
    permissionFilter,
    refetchToken,
  });

  useEffect(() => {
    dispatch(uiOperations.hideHeader());

    return () => {
      dispatch(uiOperations.showHeader());
    };
  }, [dispatch]);

  return (
    <div className={styles.ContentExplorerContainer}>
      {!token ? (
        <Spinner />
      ) : (
        <MemoryRouter initialEntries={[location]} initialIndex={0}>
          <BoxContentExplorer
            folderId={folderId}
            token={token}
            canCreateNewFolder={false}
            canDelete={false}
            canDownload={false}
            canRename={false}
            canUpload={false}
            canShare={false}
            canSetShareAccess={false}
            responseInterceptor={responseInterceptor}
          />
        </MemoryRouter>
      )}
    </div>
  );
}
