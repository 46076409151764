import {
  BoxContentPreview,
  useResponseInterceptor,
} from '@socialchorus/box-components';
import axios, { AxiosResponse, isAxiosError } from 'axios';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Spinner } from '../../../components/ui/spinner';
import { uiOperations } from '../../../models/ui';
import { useBoxToken } from '../useBoxToken';
import styles from './styles.module.scss';

type RouteParams = {
  fileId: string;
};

export function BoxPreview() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { fileId } = useParams<RouteParams>();

  const onError = useCallback(
    (err: unknown) => {
      if (
        isAxiosError(err) &&
        (err.response?.status === 403 || err.response?.status === 404)
      ) {
        history.replace('/box/not-found');
        return;
      }
      throw err;
    },
    [history]
  );

  const { token, refetch } = useBoxToken({
    resourceType: 'file',
    resourceId: fileId,
    onError,
  });

  const refetchToken = useCallback(
    async (response: AxiosResponse) => {
      const tokenData = await refetch();

      if (!tokenData) {
        return response;
      }

      return axios.request({
        ...response.config,
        headers: {
          ...response.config.headers,
          Authorization: `Bearer ${tokenData.token}`,
        },
      });
    },
    [refetch]
  );

  const responseInterceptor = useResponseInterceptor({
    refetchToken,
  });

  useEffect(() => {
    dispatch(uiOperations.hideHeader());

    return () => {
      dispatch(uiOperations.showHeader());
    };
  }, [dispatch]);

  return (
    <div className={styles.BoxPreviewContainer}>
      {!token ? (
        <Spinner />
      ) : (
        <BoxContentPreview
          showDownload={false}
          token={token}
          fileId={fileId}
          responseInterceptor={responseInterceptor}
        />
      )}
    </div>
  );
}
